<template>
  <svg
    width="110"
    height="148"
    viewBox="0 0 110 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80 14H24C18.4772 14 14 18.4772 14 24V138C14 143.523 18.4772 148 24 148H80C85.5229 148 90 143.523 90 138V24C90 18.4772 85.5229 14 80 14Z"
      fill="black"
    />
    <path
      d="M74 8H18C12.4772 8 8 12.4772 8 18V132C8 137.523 12.4772 142 18 142H74C79.5229 142 84 137.523 84 132V18C84 12.4772 79.5229 8 74 8Z"
      fill="white"
    />
    <path
      d="M68 1.25H12C6.06294 1.25 1.25 6.06294 1.25 12V126C1.25 131.937 6.06294 136.75 12 136.75H68C73.9371 136.75 78.75 131.937 78.75 126V12C78.75 6.06294 73.9371 1.25 68 1.25Z"
      stroke="#B1CCFF"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <rect x="19" y="32" width="24" height="24" rx="3" fill="#EBEBEB" />
    <rect x="49" y="32" width="24" height="24" rx="3" fill="#EBEBEB" />
    <rect x="19" y="62" width="24" height="24" rx="3" fill="#EBEBEB" />
    <rect x="49" y="62" width="24" height="24" rx="3" fill="#EBEBEB" />
    <rect x="19" y="92" width="24" height="24" rx="3" fill="#EBEBEB" />
    <rect x="49" y="92" width="24" height="24" rx="3" fill="#EBEBEB" />
    <path
      d="M88.8 76.4541H73.3123L67.6726 81.5799C67.0302 82.1637 66 81.7079 66 80.8398V76.4541V47H88.8V76.4541Z"
      fill="black"
    />
    <path
      d="M110 67.3725C110 70.1339 107.761 72.3725 105 72.3725H69.3032L63.672 77.4827C63.0294 78.0658 62 77.6099 62 76.7421V72.3725V48C62 45.2386 64.2386 43 67 43H105C107.761 43 110 45.2386 110 48V67.3725Z"
      fill="#FEDF0A"
    />
    <path
      d="M18 2H64V8C64 10.2091 62.2091 12 60 12H22C19.7909 12 18 10.2091 18 8L18 2Z"
      fill="#333333"
    />
    <rect x="33" y="6" width="16" height="2" rx="1" fill="#FCE40D" />
    <circle cx="75.9" cy="57.9" r="1.9" fill="#333333" />
    <circle cx="85.9" cy="57.9" r="1.9" fill="#333333" />
    <circle cx="94.9" cy="57.9" r="1.9" fill="#333333" />
  </svg>
</template>
