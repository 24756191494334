<template>
  <svg
    width="380"
    height="260"
    viewBox="0 0 380 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0H364C372.837 0 380 7.16344 380 16V260H0V16Z"
      fill="#E6E6E6"
    />
    <rect
      x="190"
      y="4.84212"
      width="177"
      height="177"
      rx="88.5"
      transform="rotate(45 190 4.84212)"
      fill="#FFE100"
      stroke="black"
    />
    <path
      d="M252.933 97.0676C269.501 97.0676 282.933 83.6362 282.933 67.0676C282.933 50.4991 269.501 37.0676 252.933 37.0676C236.364 37.0676 222.933 50.4991 222.933 67.0676C222.933 83.6362 236.364 97.0676 252.933 97.0676Z"
      fill="white"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M269.014 66.9596V74.0708C269.014 74.6974 268.332 75.0922 267.791 74.7789L255.474 67.672C254.929 67.3587 254.929 66.5734 255.474 66.2558L267.791 59.1489C268.336 58.8356 269.014 59.2262 269.014 59.8571V66.9682V66.9596Z"
      fill="#4570F5"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M237.616 58.9676C237.616 58.2396 238.206 57.6494 238.934 57.6494H258.915C259.643 57.6494 260.233 58.2396 260.233 58.9676V74.6974C260.233 75.4255 259.643 76.0156 258.915 76.0156H238.934C238.206 76.0156 237.616 75.4255 237.616 74.6974V58.9676Z"
      fill="#FFE100"
      stroke="black"
    />
    <path
      d="M127.067 222.933C143.636 222.933 157.067 209.501 157.067 192.933C157.067 176.364 143.636 162.933 127.067 162.933C110.499 162.933 97.0674 176.364 97.0674 192.933C97.0674 209.501 110.499 222.933 127.067 222.933Z"
      fill="white"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M126.816 201.576V207.684"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M137.366 191.026C137.366 196.85 132.646 201.576 126.816 201.576C120.987 201.576 116.266 196.856 116.266 191.026"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M131.536 183.424C131.536 180.722 129.361 178.532 126.678 178.532C123.995 178.532 121.819 180.722 121.819 183.424V190.021C121.819 192.722 123.995 194.912 126.678 194.912C129.361 194.912 131.536 192.722 131.536 190.021V183.424Z"
      fill="#4570F5"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M168.592 123H156.944V129.464H167.056V133.144H156.944V142.68H152.464V119.256H168.592V123ZM184.907 142.68L180.139 134.744H175.819V142.68H171.339V119.256H180.171C181.408 119.256 182.55 119.416 183.595 119.736C184.662 120.035 185.568 120.504 186.315 121.144C187.062 121.784 187.648 122.595 188.075 123.576C188.502 124.536 188.715 125.667 188.715 126.968V127.256C188.715 128.941 188.352 130.328 187.627 131.416C186.902 132.504 185.91 133.325 184.651 133.88L190.059 142.68H184.907ZM175.819 123.032V131.352H180.171C181.408 131.352 182.39 131.032 183.115 130.392C183.84 129.752 184.203 128.728 184.203 127.32V127.064C184.203 125.656 183.84 124.632 183.115 123.992C182.39 123.352 181.408 123.032 180.171 123.032H175.819ZM197.536 138.968H208.576V142.68H193.12V119.256H208.224V123H197.536V129.176H206.88V132.792H197.536V138.968ZM216.88 138.968H227.92V142.68H212.464V119.256H227.568V123H216.88V129.176H226.224V132.792H216.88V138.968Z"
      fill="black"
    />
  </svg>
</template>
