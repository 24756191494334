import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeveloperDocIcon = _resolveComponent("DeveloperDocIcon")!
  const _component_DeveloperSampleIcon = _resolveComponent("DeveloperSampleIcon")!
  const _component_DeveloperTesterIcon = _resolveComponent("DeveloperTesterIcon")!
  const _component_DeveloperRegistIcon = _resolveComponent("DeveloperRegistIcon")!
  const _component_DeveloperCommunityIcon = _resolveComponent("DeveloperCommunityIcon")!
  const _component_DeveloperSupportIcon = _resolveComponent("DeveloperSupportIcon")!
  const _component_GuideAppIcon = _resolveComponent("GuideAppIcon")!

  return (_ctx.iconType === 'doc')
    ? (_openBlock(), _createBlock(_component_DeveloperDocIcon, {
        key: 0,
        class: "icon-doc"
      }))
    : (_ctx.iconType === 'sample')
      ? (_openBlock(), _createBlock(_component_DeveloperSampleIcon, { key: 1 }))
      : (_ctx.iconType === 'tester')
        ? (_openBlock(), _createBlock(_component_DeveloperTesterIcon, { key: 2 }))
        : (_ctx.iconType === 'regist')
          ? (_openBlock(), _createBlock(_component_DeveloperRegistIcon, { key: 3 }))
          : (_ctx.iconType === 'community')
            ? (_openBlock(), _createBlock(_component_DeveloperCommunityIcon, { key: 4 }))
            : (_ctx.iconType === 'support')
              ? (_openBlock(), _createBlock(_component_DeveloperSupportIcon, { key: 5 }))
              : (_openBlock(), _createBlock(_component_GuideAppIcon, {
                  key: 6,
                  class: "icon-app"
                }))
}