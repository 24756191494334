<template>
  <svg
    width="117"
    height="136"
    viewBox="0 0 117 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="12" y="12" width="104.4" height="123.3" rx="5" fill="black" />
    <rect
      x="6"
      y="6"
      width="104"
      height="124"
      rx="5"
      stroke="#B1CCFF"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M1 5C1 2.79086 2.79086 1 5 1H76.2484L88.951 13.3816L103 27.5647V119C103 121.209 101.209 123 99 123H5C2.79086 123 1 121.209 1 119V5Z"
      fill="white"
      stroke="white"
      stroke-width="2"
    />
    <rect x="44" y="21" width="42" height="3" rx="1.5" fill="#E5E5E5" />
    <rect x="44" y="53" width="42" height="3" rx="1.5" fill="#E5E5E5" />
    <rect x="44" y="85" width="42" height="3" rx="1.5" fill="#E5E5E5" />
    <rect x="44" y="33" width="42" height="3" rx="1.5" fill="#E5E5E5" />
    <rect x="44" y="66" width="42" height="3" rx="1.5" fill="#E5E5E5" />
    <rect x="44" y="98" width="42" height="3" rx="1.5" fill="#E5E5E5" />
    <rect x="13" y="17" width="22.5" height="22.5" rx="11.25" fill="#FEDF0A" />
    <rect x="13" y="49" width="22.5" height="23.4" rx="11.25" fill="#FEDF0A" />
    <rect x="13" y="83" width="22.5" height="22.5" rx="11.25" fill="#FEDF0A" />
    <path
      d="M18 28.6667L22.2162 33L30 25"
      stroke="#333333"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M18 61.6667L22.2162 66L30 58"
      stroke="#333333"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M18 93.6667L22.2162 98L30 90"
      stroke="#333333"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M78 22V2.41421L91.0202 15.4344L101.586 26H82C79.7909 26 78 24.2091 78 22Z"
      fill="#EEEEEE"
      stroke="white"
      stroke-width="2"
    />
  </svg>
</template>
