<template>
  <svg
    width="380"
    height="260"
    viewBox="0 0 380 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0H364C372.837 0 380 7.16344 380 16V260H0V16Z"
      fill="#E6E6E6"
    />
    <path d="M190.5 78H271.5" stroke="black" stroke-dasharray="2 2" />
    <path
      d="M296 78C296 87.665 288.165 95.5 278.5 95.5C268.835 95.5 261 87.665 261 78C261 68.335 268.835 60.5 278.5 60.5C288.165 60.5 296 68.335 296 78Z"
      fill="#4570F5"
      stroke="black"
    />
    <path
      d="M271.445 76.9497L277.447 82.9522L286.88 72.6622"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M190.5 130H271.5" stroke="black" stroke-dasharray="2 2" />
    <path
      d="M296 130C296 139.665 288.165 147.5 278.5 147.5C268.835 147.5 261 139.665 261 130C261 120.335 268.835 112.5 278.5 112.5C288.165 112.5 296 120.335 296 130Z"
      fill="#FFE100"
      stroke="black"
    />
    <path
      d="M271.445 128.948L277.445 134.948L286.873 124.662"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M190.5 182H271.5" stroke="black" stroke-dasharray="2 2" />
    <path
      d="M296 182C296 191.665 288.165 199.5 278.5 199.5C268.835 199.5 261 191.665 261 182C261 172.335 268.835 164.5 278.5 164.5C288.165 164.5 296 172.335 296 182Z"
      fill="#4570F5"
      stroke="black"
    />
    <path
      d="M271.445 180.95L277.447 186.952L286.88 176.662"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M83 59.3712C83 54.1956 85.3376 50 88.2211 50H197.779C200.662 50 203 54.1956 203 59.3712V200.629C203 205.804 200.662 210 197.779 210H88.2211C85.3376 210 83 205.804 83 200.629V59.3712Z"
      fill="white"
    />
    <path
      d="M83 55.2211C83 52.3376 85.3376 50 88.2211 50H197.779C200.662 50 203 52.3376 203 55.2211V67.1429H83V55.2211Z"
      fill="#4570F5"
    />
    <path
      d="M83.5 59.3712C83.5 56.8522 84.0699 54.5965 84.966 52.9881C85.8714 51.3631 87.0426 50.5 88.2211 50.5H197.779C198.957 50.5 200.129 51.3631 201.034 52.9881C201.93 54.5965 202.5 56.8522 202.5 59.3712V200.629C202.5 203.148 201.93 205.404 201.034 207.012C200.129 208.637 198.957 209.5 197.779 209.5H88.2211C87.0426 209.5 85.8714 208.637 84.966 207.012C84.0699 205.404 83.5 203.148 83.5 200.629V59.3712Z"
      stroke="black"
    />
    <circle cx="93.8975" cy="58.7545" r="2.32653" fill="#EBEBEB" />
    <circle cx="102.407" cy="58.7543" r="2.32653" fill="#EBEBEB" />
    <circle cx="110.919" cy="58.7543" r="2.32653" fill="#EBEBEB" />
    <rect
      x="117"
      y="94.093"
      width="16"
      height="71"
      transform="rotate(-90 117 94.093)"
      fill="#FFE100"
    />
    <path
      d="M117 100.093L117 99.093L188 99.093V100.093L117 100.093Z"
      fill="#EBEBEB"
    />
    <path
      d="M117 106.093L117 105.093L188 105.093V106.093L117 106.093Z"
      fill="#EBEBEB"
    />
    <path
      d="M117 112.093L117 111.093L188 111.093V112.093L117 112.093Z"
      fill="#EBEBEB"
    />
    <path
      d="M117 118.093L117 117.093L188 117.093V118.093L117 118.093Z"
      fill="#EBEBEB"
    />
    <path
      d="M117 124.093L117 123.093L188 123.093V124.093L117 124.093Z"
      fill="#EBEBEB"
    />
    <rect
      x="117"
      y="145.093"
      width="16"
      height="71"
      transform="rotate(-90 117 145.093)"
      fill="#EBEBEB"
    />
    <rect
      x="117"
      y="166.093"
      width="16"
      height="71"
      transform="rotate(-90 117 166.093)"
      fill="#FFE100"
    />
    <rect
      x="117"
      y="187.093"
      width="16"
      height="71"
      transform="rotate(-90 117 187.093)"
      fill="#EBEBEB"
    />
    <path
      d="M103.91 90.86L103.14 88.284H99.262L98.492 90.86H96.42L99.794 80.612H102.594L105.982 90.86H103.91ZM99.71 86.786H102.692L101.194 81.788L99.71 86.786Z"
      fill="black"
    />
    <path
      d="M103.336 136.428C103.896 136.605 104.353 136.895 104.708 137.296C105.063 137.697 105.24 138.22 105.24 138.864V138.976C105.24 139.471 105.151 139.9 104.974 140.264C104.806 140.619 104.568 140.917 104.26 141.16C103.952 141.393 103.583 141.571 103.154 141.692C102.734 141.804 102.272 141.86 101.768 141.86H97.4V131.612H101.32C101.805 131.612 102.253 131.673 102.664 131.794C103.084 131.906 103.439 132.074 103.728 132.298C104.027 132.522 104.26 132.802 104.428 133.138C104.596 133.474 104.68 133.857 104.68 134.286V134.412C104.68 134.888 104.554 135.294 104.302 135.63C104.059 135.957 103.737 136.223 103.336 136.428ZM99.304 133.138V136.008H101.306C101.745 136.008 102.09 135.901 102.342 135.686C102.603 135.462 102.734 135.107 102.734 134.622V134.51C102.734 134.034 102.603 133.689 102.342 133.474C102.09 133.25 101.745 133.138 101.306 133.138H99.304ZM103.308 138.752C103.308 138.239 103.163 137.865 102.874 137.632C102.594 137.389 102.216 137.268 101.74 137.268H99.304V140.334H101.74C102.216 140.334 102.594 140.217 102.874 139.984C103.163 139.751 103.308 139.382 103.308 138.878V138.752Z"
      fill="black"
    />
    <path
      d="M101.586 185.084C100.961 185.084 100.359 184.981 99.78 184.776C99.2107 184.571 98.7067 184.267 98.268 183.866C97.8387 183.465 97.4933 182.965 97.232 182.368C96.9707 181.761 96.84 181.061 96.84 180.268V179.218C96.84 178.425 96.9707 177.729 97.232 177.132C97.4933 176.525 97.8387 176.021 98.268 175.62C98.7067 175.209 99.2107 174.901 99.78 174.696C100.359 174.491 100.961 174.388 101.586 174.388H101.6C102.225 174.388 102.813 174.491 103.364 174.696C103.924 174.892 104.414 175.172 104.834 175.536C105.254 175.9 105.59 176.343 105.842 176.866C106.094 177.379 106.234 177.958 106.262 178.602H104.372C104.344 178.201 104.251 177.841 104.092 177.524C103.933 177.197 103.728 176.927 103.476 176.712C103.233 176.497 102.949 176.334 102.622 176.222C102.305 176.101 101.969 176.04 101.614 176.04H101.6C101.245 176.04 100.9 176.105 100.564 176.236C100.228 176.357 99.9293 176.544 99.668 176.796C99.4067 177.039 99.1967 177.347 99.038 177.72C98.8887 178.084 98.814 178.509 98.814 178.994V180.492C98.814 180.977 98.8887 181.407 99.038 181.78C99.1967 182.144 99.4067 182.452 99.668 182.704C99.9293 182.947 100.228 183.133 100.564 183.264C100.9 183.385 101.245 183.446 101.6 183.446H101.614C101.969 183.446 102.305 183.39 102.622 183.278C102.949 183.157 103.233 182.989 103.476 182.774C103.728 182.559 103.933 182.293 104.092 181.976C104.251 181.659 104.344 181.295 104.372 180.884H106.262C106.234 181.519 106.094 182.097 105.842 182.62C105.59 183.133 105.254 183.572 104.834 183.936C104.414 184.3 103.924 184.585 103.364 184.79C102.813 184.986 102.225 185.084 101.6 185.084H101.586Z"
      fill="black"
    />
  </svg>
</template>
