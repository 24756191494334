<template>
  <svg
    width="380"
    height="260"
    viewBox="0 0 380 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0H364C372.837 0 380 7.16344 380 16V260H0V16Z"
      fill="#E6E6E6"
    />
    <path
      d="M172.815 130.744C172.815 125.362 168.452 121 163.071 121H69.7436C64.3623 121 60 125.362 60 130.744V169.59C60 174.971 64.3624 179.333 69.7436 179.333H163.071C168.452 179.333 172.815 174.971 172.815 169.59V130.744Z"
      fill="#FFE100"
      stroke="black"
    />
    <path
      d="M115.763 143C136.881 143 154 159.249 154 179.292H77.5266C77.5266 159.249 94.6458 143 115.763 143Z"
      fill="white"
      stroke="black"
    />
    <circle
      cx="136.038"
      cy="120.068"
      r="5.01561"
      fill="#F1D3B6"
      stroke="black"
    />
    <circle
      cx="98.2173"
      cy="120.068"
      r="5.01561"
      fill="#F1D3B6"
      stroke="black"
    />
    <path
      d="M137.359 120.011C134.065 134.211 124.736 139.536 116.979 139.536C109.268 139.536 101.02 133.415 96.5983 119.988C96.6328 107.62 104.719 97.7173 116.979 97.7173C129.246 97.7173 137.334 107.632 137.359 120.011Z"
      fill="#F1D3B6"
      stroke="black"
    />
    <path
      d="M111.219 127.159C112.468 128.595 114.65 129.548 117.128 129.548C119.607 129.548 121.789 128.595 123.038 127.159"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M109.643 116.128V118.098M124.614 116.128V118.098"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M118.704 123.416C118.704 123.416 117.91 121.643 116.931 121.643C115.952 121.643 115.158 123.416 115.158 123.416"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M91.1258 103.324C91.7168 108.839 94 113.5 95.8534 117.507C95.9847 118.36 96.3261 119.989 96.6413 119.673C97.0353 119.28 97.4293 108.839 103.142 104.9C108.855 100.96 122.13 97.9999 126 97.9999C132.895 97.9999 129.607 102.689 134.66 104.9C137.812 106.278 135.645 108.839 135.842 113.567C135.999 117.349 137.5 119 137.5 119L138.796 116.522C140.672 117.387 142.342 116.767 142.342 104.9C142.342 92.2925 136.827 86.9739 130.408 85.6299C124.613 84.4167 121.462 84.8071 117.325 82.6402C109.604 78.5958 99.5001 82.9999 95.8534 88.1558C90.3511 95.9353 90.7034 99.382 91.1258 103.324Z"
      fill="black"
      stroke="black"
    />
    <path
      d="M112.4 105.687C119.965 105.214 126.189 103.586 127.568 100.959C129.459 113.094 138.208 113.961 137.418 117.112C137.943 115.799 138.993 111.282 138.993 103.717C138.993 94.262 131.508 96.4288 120.477 95.0499C109.446 93.671 101.763 98.9896 97.0354 105.687C92.3077 112.385 96.0505 120.067 97.0354 117.112C98.0203 114.158 102.945 106.278 112.4 105.687Z"
      fill="black"
      stroke="black"
    />
    <path
      opacity="0.4"
      d="M110.957 90.2912L112.667 96.932"
      stroke="#4570F5"
      stroke-linecap="round"
    />
    <path
      opacity="0.4"
      d="M104.686 86.7355L111.589 101.393"
      stroke="#4570F5"
      stroke-linecap="round"
    />
    <path
      opacity="0.4"
      d="M96.229 91.8338L102.146 97.5693"
      stroke="#4570F5"
      stroke-linecap="round"
    />
    <path
      opacity="0.4"
      d="M96.0982 97.6187L105.126 102.499"
      stroke="#4570F5"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M214 91C211.791 91 210 92.7909 210 95L210 137C210 139.209 211.791 141 214 141L256 141C258.209 141 260 139.209 260 137L260 95C260 92.7909 258.209 91 256 91L235.96 91L222.246 83L224.243 91L214 91Z"
      fill="white"
    />
    <path
      d="M210 95L209.5 95L210 95ZM214 91L214 91.5L214 91ZM210 137L210.5 137L210 137ZM214 141L214 141.5L214 141ZM256 141L256 140.5L256 141ZM260 137L260.5 137L260 137ZM260 95L259.5 95L260 95ZM256 91L256 90.5L256 91ZM235.96 91L235.96 91.5L235.825 91.5L235.709 91.4319L235.96 91ZM222.246 83L221.761 83.1211L221.474 81.9707L222.498 82.5681L222.246 83ZM224.243 91L224.728 90.8789L224.883 91.5L224.243 91.5L224.243 91ZM209.5 95C209.5 92.5147 211.515 90.5 214 90.5L214 91.5C212.067 91.5 210.5 93.067 210.5 95L209.5 95ZM209.5 137L209.5 95L210.5 95L210.5 137L209.5 137ZM214 141.5C211.515 141.5 209.5 139.485 209.5 137L210.5 137C210.5 138.933 212.067 140.5 214 140.5L214 141.5ZM256 141.5L214 141.5L214 140.5L256 140.5L256 141.5ZM260.5 137C260.5 139.485 258.485 141.5 256 141.5L256 140.5C257.933 140.5 259.5 138.933 259.5 137L260.5 137ZM260.5 95L260.5 137L259.5 137L259.5 95L260.5 95ZM256 90.5C258.485 90.5 260.5 92.5147 260.5 95L259.5 95C259.5 93.067 257.933 91.5 256 91.5L256 90.5ZM235.96 90.5L256 90.5L256 91.5L235.96 91.5L235.96 90.5ZM235.709 91.4319L221.994 83.4319L222.498 82.5681L236.212 90.5681L235.709 91.4319ZM222.731 82.8789L224.728 90.8789L223.757 91.1211L221.761 83.1211L222.731 82.8789ZM214 90.5L224.243 90.5L224.243 91.5L214 91.5L214 90.5Z"
      fill="black"
    />
    <path d="M227.143 110.143L222 115L227.143 119.857" stroke="black" />
    <path d="M238.286 109L232 121" stroke="black" />
    <path d="M243.142 110.143L248.285 115L243.142 119.857" stroke="black" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M197 159C194.791 159 193 160.791 193 163V185C193 187.209 194.791 189 197 189H223.029L221.031 197.005L234.754 189H267C269.209 189 271 187.209 271 185V163C271 160.791 269.209 159 267 159H197Z"
      fill="#4570F5"
    />
    <path
      d="M223.029 189L223.514 189.121L223.669 188.5H223.029V189ZM221.031 197.005L220.546 196.884L220.259 198.034L221.283 197.437L221.031 197.005ZM234.754 189V188.5H234.619L234.502 188.568L234.754 189ZM193.5 163C193.5 161.067 195.067 159.5 197 159.5V158.5C194.515 158.5 192.5 160.515 192.5 163H193.5ZM193.5 185V163H192.5V185H193.5ZM197 188.5C195.067 188.5 193.5 186.933 193.5 185H192.5C192.5 187.485 194.515 189.5 197 189.5V188.5ZM223.029 188.5H197V189.5H223.029V188.5ZM221.516 197.126L223.514 189.121L222.544 188.879L220.546 196.884L221.516 197.126ZM234.502 188.568L220.779 196.573L221.283 197.437L235.006 189.432L234.502 188.568ZM267 188.5H234.754V189.5H267V188.5ZM270.5 185C270.5 186.933 268.933 188.5 267 188.5V189.5C269.485 189.5 271.5 187.485 271.5 185H270.5ZM270.5 163V185H271.5V163H270.5ZM267 159.5C268.933 159.5 270.5 161.067 270.5 163H271.5C271.5 160.515 269.485 158.5 267 158.5V159.5ZM197 159.5H267V158.5H197V159.5Z"
      fill="black"
    />
    <path d="M201 169H261" stroke="black" />
    <path d="M201 176H231" stroke="black" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M246 125C243.791 125 242 126.791 242 129V169C242 171.209 243.791 173 246 173H295.031L308.754 181.005L306.756 173H316C318.209 173 320 171.209 320 169V129C320 126.791 318.209 125 316 125H246Z"
      fill="white"
    />
    <path
      d="M295.031 173V172.5H295.166L295.283 172.568L295.031 173ZM308.754 181.005L309.239 180.884L309.526 182.034L308.502 181.437L308.754 181.005ZM306.756 173L306.271 173.121L306.116 172.5H306.756V173ZM241.5 129C241.5 126.515 243.515 124.5 246 124.5V125.5C244.067 125.5 242.5 127.067 242.5 129H241.5ZM241.5 169V129H242.5V169H241.5ZM246 173.5C243.515 173.5 241.5 171.485 241.5 169H242.5C242.5 170.933 244.067 172.5 246 172.5V173.5ZM295.031 173.5H246V172.5H295.031V173.5ZM295.283 172.568L309.006 180.573L308.502 181.437L294.779 173.432L295.283 172.568ZM308.269 181.126L306.271 173.121L307.241 172.879L309.239 180.884L308.269 181.126ZM316 173.5H306.756V172.5H316V173.5ZM320.5 169C320.5 171.485 318.485 173.5 316 173.5V172.5C317.933 172.5 319.5 170.933 319.5 169H320.5ZM320.5 129V169H319.5V129H320.5ZM316 124.5C318.485 124.5 320.5 126.515 320.5 129H319.5C319.5 127.067 317.933 125.5 316 125.5V124.5ZM246 124.5H316V125.5H246V124.5Z"
      fill="black"
    />
    <path d="M251 138H311" stroke="black" />
    <path d="M251 145H311" stroke="black" />
    <path d="M251 152H311" stroke="black" />
    <path d="M251 159H281" stroke="black" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M140.443 72.8337C135.318 69.0044 132 62.8897 132 56.0001C132 44.4021 141.402 35 153 35C164.598 35 174 44.4021 174 56.0001C174 67.5982 164.598 77.0002 153 77.0002C150.646 77.0002 148.383 76.6129 146.27 75.8986L138.555 80.3991L140.443 72.8337Z"
      fill="#4570F5"
    />
    <path
      d="M140.443 72.8337L140.742 72.4332C140.903 72.5537 140.977 72.7593 140.928 72.9548L140.443 72.8337ZM146.27 75.8986L146.018 75.4667C146.143 75.3939 146.293 75.3786 146.43 75.4249L146.27 75.8986ZM138.555 80.3991L138.807 80.831C138.633 80.9323 138.416 80.9197 138.255 80.799C138.094 80.6784 138.021 80.4731 138.07 80.278L138.555 80.3991ZM132.5 56.0001C132.5 62.7254 135.738 68.6942 140.742 72.4332L140.143 73.2342C134.898 69.3146 131.5 63.0541 131.5 56.0001L132.5 56.0001ZM153 35.5C141.678 35.5 132.5 44.6782 132.5 56.0001L131.5 56.0001C131.5 44.1259 141.126 34.5 153 34.5L153 35.5ZM173.5 56.0001C173.5 44.6782 164.322 35.5 153 35.5L153 34.5C164.874 34.5 174.5 44.1259 174.5 56.0001L173.5 56.0001ZM153 76.5002C164.322 76.5002 173.5 67.322 173.5 56.0001L174.5 56.0001C174.5 67.8743 164.874 77.5002 153 77.5002L153 76.5002ZM146.43 75.4249C148.492 76.1221 150.701 76.5002 153 76.5002L153 77.5002C150.591 77.5002 148.273 77.1038 146.11 76.3722L146.43 75.4249ZM146.522 76.3305L138.807 80.831L138.303 79.9672L146.018 75.4667L146.522 76.3305ZM138.07 80.278L139.958 72.7126L140.928 72.9548L139.04 80.5201L138.07 80.278Z"
      fill="black"
    />
    <path
      d="M150.143 63.6941C150.143 64.9498 151.193 66 152.448 66C153.704 66 154.754 64.9498 154.754 63.6941C154.754 62.4384 153.704 61.3881 152.448 61.3881C151.193 61.3881 150.143 62.4384 150.143 63.6941ZM150.576 57.9406C150.576 59.1279 151.307 59.79 152.448 59.79C153.59 59.79 154.321 59.1279 154.321 57.9406V57.484C154.321 56.9817 154.458 56.6621 154.937 56.3425L155.645 55.8858C157.448 54.7215 158.704 53.3973 158.704 51.2283C158.704 48.0548 156.284 46 152.585 46C149.64 46 147.471 47.8493 147.038 50.1553C146.855 51.137 147.357 51.8676 148.248 52.1416C149.206 52.4384 150.165 52.0502 150.439 51.137C150.782 50.0411 151.558 49.4247 152.837 49.4247C154.161 49.4247 154.914 50.2694 154.914 51.2055C154.914 52.0959 154.229 52.8493 153.179 53.4886L152.403 53.968C150.942 54.8813 150.576 55.5434 150.576 57.2785V57.9406Z"
      fill="white"
      stroke="#4570F5"
    />
  </svg>
</template>
