
import { defineComponent } from "vue";
import GuideDocIcon from "@/components/atoms/icon/GuideDocIcon/GuideDocIcon.vue";
import GuideCommuIcon from "@/components/atoms/icon/GuideCommuIcon/GuideCommuIcon.vue";
import GuideAppIcon from "@/components/atoms/icon/GuideAppIcon/GuideAppIcon.vue";
import DeveloperDocIcon from "@/components/atoms/icon/DeveloperDocIcon/DeveloperDocIcon.vue";
import DeveloperSampleIcon from "@/components/atoms/icon/DeveloperSampleIcon/DeveloperSampleIcon.vue";
import DeveloperTesterIcon from "@/components/atoms/icon/DeveloperTesterIcon/DeveloperTesterIcon.vue";
import DeveloperRegistIcon from "@/components/atoms/icon/DeveloperRegistIcon/DeveloperRegistIcon.vue";
import DeveloperCommunityIcon from "@/components/atoms/icon/DeveloperCommunityIcon/DeveloperCommunityIcon.vue";
import DeveloperSupportIcon from "@/components/atoms/icon/DeveloperSupportIcon/DeveloperSupportIcon.vue";

export default defineComponent({
  components: {
    GuideDocIcon,
    GuideCommuIcon,
    GuideAppIcon,
    DeveloperDocIcon,
    DeveloperSampleIcon,
    DeveloperTesterIcon,
    DeveloperRegistIcon,
    DeveloperCommunityIcon,
    DeveloperSupportIcon
  },
  props: {
    iconType: {
      type: String
    }
  }
});
