<template>
  <svg
    width="380"
    height="260"
    viewBox="0 0 380 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0H364C372.837 0 380 7.16344 380 16V260H0V16Z"
      fill="#E6E6E6"
    />
    <path
      d="M236 217C252.569 217 266 203.569 266 187C266 170.431 252.569 157 236 157C219.431 157 206 170.431 206 187C206 203.569 219.431 217 236 217Z"
      fill="#FFE100"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M221 193.652C224.171 197.297 229.71 199.717 236 199.717C242.29 199.717 247.829 197.297 251 193.652"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M227 180.903V185.684" stroke="black" stroke-linecap="round" />
    <path d="M244.517 180.903V185.684" stroke="black" stroke-linecap="round" />
    <rect
      x="227.5"
      y="81.5"
      width="78"
      height="42"
      fill="#D9D9D9"
      stroke="black"
    />
    <rect
      x="221.5"
      y="75.5"
      width="78"
      height="42"
      fill="white"
      stroke="black"
    />
    <path d="M250 88L241 96.5L250 105" stroke="black" />
    <path d="M266 86L255 107" stroke="black" />
    <path d="M271 88L280 96.5L271 105" stroke="black" />
    <path
      d="M311.5 74.5C311.5 81.1274 306.127 86.5 299.5 86.5C292.873 86.5 287.5 81.1274 287.5 74.5C287.5 67.8726 292.873 62.5 299.5 62.5C306.127 62.5 311.5 67.8726 311.5 74.5Z"
      fill="#4570F5"
      stroke="black"
    />
    <path
      d="M294.601 73.7695L298.767 77.9362L305.315 70.7933"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="95.5"
      y="53.5"
      width="93"
      height="125"
      fill="white"
      stroke="#111111"
    />
    <rect
      x="85.5"
      y="63.5"
      width="93"
      height="125"
      fill="white"
      stroke="#111111"
    />
    <path
      d="M75.5 73.5H168.5V182.793L152.793 198.5H75.5V73.5Z"
      fill="white"
      stroke="#111111"
    />
    <path d="M85 129H159" stroke="black" />
    <path d="M85 136H159" stroke="black" />
    <path d="M85 143H159" stroke="black" />
    <path d="M85 150H159" stroke="black" />
    <path d="M85 157H159" stroke="black" />
    <path d="M85 164H159" stroke="black" />
    <path d="M85 171H159" stroke="black" />
    <path d="M85 178H115" stroke="black" />
    <rect
      x="85.5"
      y="85.5"
      width="32"
      height="27"
      fill="#FFE100"
      stroke="black"
    />
    <rect
      x="126.5"
      y="85.5"
      width="32"
      height="27"
      rx="13.5"
      fill="#4570F5"
      stroke="black"
    />
    <path
      d="M153.5 183.5H167.793L153.5 197.793V183.5Z"
      fill="#D9D9D9"
      stroke="black"
    />
  </svg>
</template>
