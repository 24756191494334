export const data = {
  sitemapTitle: "개발자센터 - 카카오 i 커넥트 라이브",
  title: "반가워요, Developer",
  description:
    "커넥트 라이브 개발자 센터에 오신 것을 환경합니다.<br>새로운 라이브 경험을 함께 나누어요.",
  support: {
    title: "개발자 센터 대표 메뉴",
    list: [
      {
        name: "doc",
        title: "쉽고 편하게  개발 하세요.",
        description: "개발 가이드와 각 SDK별 샘플코드를<br />확인할 수 있어요",
        linktitle: "기술문서 바로가기",
        url: "https://docs.kakaoi.ai/connect_live/ver2.0/"
      },
      {
        name: "sample",
        title: "이렇게 해보세요 !",
        description:
          "영상/음성 통화, 방송, 회의 등<br />커넥트 라이브를 활용한 예제를 확인해 보세요",
        linktitle: "예제 바로가기",
        url: "https://sample.connectlive.kakaoi.ai/"
      },
      {
        name: "tester",
        title: "스트리밍에 문제가 있으세요?",
        description:
          "디바이스, 네트워크 등 어떤 문제가 있는지<br />기본적인 트러블 슈팅을 할 수 있어요",
        linktitle: "RTC Tester 바로가기",
        url: "https://test.connectlive.kakaoi.ai/"
      },
      {
        name: "regist",
        title: "무료로 개발해보세요.",
        description:
          "최대 10명까지, 스트리밍당 3분까지 무료로<br />마음껏 개발하고 테스트 하세요",
        linktitle: "콘솔 사용하기",
        url: "https://console.connectlive.kakaoi.ai/"
      },
      {
        name: "community",
        title: "함께 나누어요.",
        description:
          "커넥트 라이브 관련 이야기들을<br />자유롭게 나눌 수 있는 공간이에요.",
        linktitle: "커뮤니티 바로가기",
        url: "https://community.remotemonster.com"
      },
      {
        name: "support",
        title: "전문가가 도와 드릴게요!",
        description: "기술지원이 필요하시면<br /> 메일 지원을 요청해주세요.",
        linktitle: "메일 지원 요청하기"
        // url: "/inquiry/tech"
      }
    ]
  },
  sdk: {
    title: "SDK를 다운 받으세요",
    description:
      "Web, Android, iOS용 최신 SDK가 준비되어있습니다. 빠르게 받으시고<br> 자유롭게 적용해 보세요",
    url: "https://github.com/kakaoi-clive"
  },
  faq: {
    title: "자주 묻는 질문(개발)",
    list: [
      {
        question: "계약 이전에 카카오 i 커넥트 라이브를 이용하고 싶습니다.",
        answer:
          "웹 콘솔 (<a href='https://console.connectlive.kakaoi.ai/' target='_blank' style='color: #00f;'>console.connectlive.kakaoi.ai</a>) 에서 무료로 가입할 수 있습니다.<br />앱 개발 및 테스트를 위해 무료로 Connect Live를 이용해 보실 수 있습니다.<br />무료로 이용하는 기간 동안은 룸당 최대 10명까지만 참여가능하며, 3분 동안 스트리밍이 유지되고 자동으로 끊어집니다."
      },
      {
        question: "Service ID 등 인증키를 분실하였습니다. 어떻게 해야하나요?",
        answer:
          "웹 콘솔 (<a href='https://console.connectlive.kakaoi.ai/' target='_blank' style='color: #00f;'>https://console.connectlive.kakaoi.ai/</a>)에 로그인하여 서비스를 선택하면<br />발급받은 Service ID 등 인증정보를 확인할 수 있습니다."
      },
      {
        question:
          "Developer Plan을 이용하여 개발을 완료하였습니다. 계약 절차는 어떻게 되나요?",
        answer:
          "도입문의를 통해 사업담당자와 계약 조건을 협의 후, 별도로 정식 인증키를 발급받으실 수 있습니다.<br />Developer Plan에서 발급받으신 Key는 삭제되지 않으며, 테스트로 계속 이용가능합니다."
      }
    ]
  },
  contact: {
    list: [
      {
        title: "커뮤니티 바로가기",
        description: "정보를 공유하세요",
        url: "https://community.remotemonster.com"
      },
      {
        title: "e-mail 문의하기",
        description: "빠르게 답변드리겠습니다",
        url: "c.live@kakaoenterprise.com"
      },
      {
        title: "Blog 바로가기",
        description: "(구)리모트몬스터 블로그입니다",
        url: "https://blog.remotemonster.com"
      }
    ]
  },
  mobile: {
    title: "반가워요, Developer",
    description:
      "커넥트 라이브 개발자 센터에 오신 것을 환경합니다.<br>새로운 라이브 경험을 함께 나누어요.",
    support: {
      title: "개발자 센터 대표 메뉴",
      list: [
        {
          name: "doc",
          title: "쉽고 편하게  개발 하세요.",
          description:
            "개발 가이드와 각 SDK별 샘플코드를<br />확인할 수 있어요",
          linktitle: "기술문서 바로가기",
          url: "https://docs.kakaoi.ai/connect_live/ver2.0/"
        },
        {
          name: "sample",
          title: "이렇게 해보세요 !",
          description:
            "영상/음성 통화, 방송, 회의 등<br />커넥트 라이브를 활용한 예제를 확인해 보세요",
          linktitle: "예제 바로가기",
          url: "https://sample.connectlive.kakaoi.ai/"
        },
        {
          name: "tester",
          title: "스트리밍에 문제가 있으세요?",
          description:
            "디바이스, 네트워크 등 어떤 문제가 있는지<br />기본적인 트러블 슈팅을 할 수 있어요",
          linktitle: "RTC Tester 바로가기",
          url: "https://test.connectlive.kakaoi.ai/"
        },
        {
          name: "regist",
          title: "무료로 개발해보세요.",
          description:
            "최대 10명까지, 스트리밍당 3분까지 무료로<br />마음껏 개발하고 테스트 하세요",
          linktitle: "콘솔 사용하기",
          url: "https://console.connectlive.kakaoi.ai/"
        },
        {
          name: "community",
          title: "함께 나누어요.",
          description:
            "커넥트 라이브 관련 이야기들을<br />자유롭게 나눌 수 있는 공간이에요.",
          linktitle: "커뮤니티 바로가기",
          url: "https://community.remotemonster.com"
        },
        {
          name: "support",
          title: "전문가가 도와 드릴게요!",
          description: "기술지원이 필요하시면<br /> 메일 지원을 요청해주세요.",
          linktitle: "메일 지원 요청하기"
          // url: "/inquiry/tech"
        }
      ]
    },
    sdk: {
      title: "SDK를 다운 받으세요",
      description:
        "Web, Android, iOS용 최신 SDK가 준비되어있습니다. 빠르게 받으시고 자유롭게 적용해 보세요",
      url: "https://github.com/kakaoi-clive"
    },
    faq: {
      title: "자주 묻는 질문(개발)",
      list: [
        {
          question: "계약 이전에 카카오 i 커넥트 라이브를 이용하고 싶습니다.",
          answer:
            "웹 콘솔 (<a href='https://console.connectlive.kakaoi.ai/' target='_blank' style='color: #00f'>console.connectlive.kakaoi.ai</a>) 에서 무료로 가입할 수 있습니다. 앱 개발 및 테스트를 위해 무료로 Connect Live를 이용해 보실 수 있습니다. 무료로 이용하는 기간 동안은 룸당 최대 10명까지만 참여가능하며, 3분 동안 스트리밍이 유지되고 자동으로 끊어집니다."
        },
        {
          question: "Service ID 등 인증키를 분실하였습니다. 어떻게 해야하나요?",
          answer:
            "웹 콘솔 (<a href='https://console.connectlive.kakaoi.ai/' target='_blank' style='color: #00f'>https://console.connectlive.kakaoi.ai/</a>)에 로그인하여 서비스를 선택하면 발급받은 Service ID 등 인증정보를 확인할 수 있습니다."
        },
        {
          question:
            "Developer Plan을 이용하여 개발을 완료하였습니다. 계약 절차는 어떻게 되나요?",
          answer:
            "도입문의를 통해 사업담당자와 계약 조건을 협의 후, 별도로 정식 인증키를 발급받으실 수 있습니다. Developer Plan에서 발급받으신 Key는 삭제되지 않으며, 테스트로 계속 이용가능합니다."
        }
      ]
    },
    contact: {
      list: [
        {
          title: "커뮤니티 바로가기",
          description: "정보를 공유하세요",
          url: "https://community.remotemonster.com"
        },
        {
          title: "e-mail 문의하기",
          description: "빠르게 답변드리겠습니다",
          url: "c.live@kakaoenterprise.com"
        },
        {
          title: "Blog 바로가기",
          description: "(구)리모트몬스터 블로그입니다",
          url: "https://blog.remotemonster.com"
        }
      ]
    }
  }
};
