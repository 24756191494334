<template>
  <svg
    width="179"
    height="121"
    viewBox="0 0 179 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="13" y="13" width="130.938" height="108" rx="5" fill="black" />
    <rect
      x="8"
      y="8"
      width="128"
      height="106"
      rx="4"
      fill="white"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M7 12C7 9.23858 9.23858 7 12 7H132C134.761 7 137 9.23858 137 12V27H7V12Z"
      fill="#1E1E1E"
    />
    <rect x="16" y="46" width="20" height="4" rx="2" fill="#E5E5E5" />
    <rect x="44" y="46" width="35.15" height="4" rx="2" fill="#E5E5E5" />
    <rect x="16" y="75" width="68" height="4" rx="2" fill="#E5E5E5" />
    <rect x="16" y="60" width="46" height="4" rx="2" fill="#E5E5E5" />
    <rect x="16" y="90" width="46" height="4" rx="2" fill="#E5E5E5" />
    <circle cx="131.84" cy="70" r="31" fill="black" />
    <circle cx="19" cy="17" r="3" fill="#FCE40D" />
    <rect
      x="151"
      y="89"
      width="1.9"
      height="29.6849"
      transform="rotate(-45 151 89)"
      fill="#B1CCFF"
    />
    <path
      d="M156.852 52.5052C165.412 67.3322 160.332 86.2915 145.505 94.852C130.678 103.412 111.719 98.3322 103.158 83.5052C94.598 68.6781 99.6781 49.7188 114.505 41.1584C129.332 32.598 148.292 37.6781 156.852 52.5052Z"
      fill="#FEDF0A"
    />
    <rect x="113" y="57" width="34" height="2" rx="1" fill="#333333" />
    <rect x="113" y="77" width="28" height="2" rx="1" fill="#333333" />
    <rect x="113" y="67" width="20" height="2" rx="1" fill="#333333" />
    <rect
      x="157"
      y="98"
      width="6"
      height="25"
      rx="3"
      transform="rotate(-45 157 98)"
      fill="black"
    />
    <path
      d="M131 109H121.511H6C3.23858 109 1 106.761 1 104V6C1 3.23858 3.23858 1 6 1H126C128.761 1 131 3.23858 131 6V20.5929"
      stroke="#B1CCFF"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
